import React from "react"

function Vcard() {
  return (
    <>
      <iframe
        src="/vcard/billfruscojr/index.html"
        style={{ width: "100%", height: "100vh" }}
      />
    </>
  )
}

export default Vcard
